import { motion } from "framer-motion";
import React from "react";
import { useWindowSize } from "react-use";
import IntersectionBox from "../animations/IntersectionBox";
import { zoomIn } from "../animations/variants";

function GetQuoteSection({ title, description }) {
  const { width } = useWindowSize();

  if (width < 750) {
    return <></>;
  } else {
    return (
      <div className='get-started container-fluid'>
        <IntersectionBox threshold={0.3} variants={zoomIn}>
          <div className='free-quote'>
            <div className='row'>
              <div className='quote-info col-lg-7 col-md-7 col-sm-7 col-xs-12'>
                <h2 className='quote-head'>{title} </h2>
                <p className='quote-desc'>{description}</p>
              </div>
              <div className='get-quote-email'>
                {/* <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className='clippy_button started-btn'
                  onClick={() => {
                    window.open("https://app.custiv.com/quotes/new", "_blank");
                  }}>
                  Get Quote
                </motion.button> */}
                <p className='get-quote-email-head'>
                  Send us your requirements to
                </p>
                <p className='get-quote-email-text'>
                  <a href='mailto:rfq@custiv.com'> rfq@custiv.com </a>
                </p>
              </div>
            </div>
          </div>
        </IntersectionBox>
      </div>
    );
  }
}

GetQuoteSection.defaultProps = {
  title: "Upload Parts and Get Quote",
  description:
    "Submit your requirement by uploading design or drawings to get a quick quote.",
};

export default GetQuoteSection;
