import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { fadeInUp } from "../animations/variants";
import IntersectionBox from "./IntersectionBox";

const fade = {
  show: {
    opacity: 1,
    transition: {
      delay: 0.2,
      duration: 0.5,
      type: "spring",
      bounce: 0.9,
      mass: 1,
      velocity: 1,
      damping: 13,
    },
    y: 0,
  },
  hidden: {
    opacity: 0,
    y: 50,
  },
};

function TrailingParagraph({ paragraph }) {
  const [loaded, setLoaded] = useState(false);
  const [elements, setElements] = useState([]);

  useEffect(() => {
    if (paragraph) {
      const elems = paragraph.split(" ").map((text) => (
        <IntersectionBox
          threshold={0.9}
          variants={fade}
          className="trailing-word"
        >
          {`${text}`}&nbsp;
        </IntersectionBox>
      ));
      setElements(elems);
    }
    setLoaded(true);
  }, []);

  if (!loaded) {
    return <></>;
  } else {
    return (
      <>
        {elements.map((elem) => (
          <>{elem}</>
        ))}
      </>
    );
  }
}

TrailingParagraph.defaultProps = {
  paragraph:
    "This is a random paragraph that I want to animate. This will be split into multiple components to trail a paragraph animation. Inspired by strapi.io.",
};

export default TrailingParagraph;
